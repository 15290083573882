<template>
  <a-row :gutter="25">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col 
      :xxl="24" 
      :lg="24" 
      :md="24"
      :xs="24">
        <sdCards
            class="card"
            :style="
                `background-image: url(${dataDOM.base_url + dataDOM.image});
                background-color: #cccccc;
                height: 288px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;`">
        </sdCards>
        <sdCards class="card">
            <a-row :gutter="25">
                <a-col 
                    :xxl="24" 
                    :lg="24" 
                    :md="24"
                    :xs="24">
                    <h3 class="">Descripción</h3>
                </a-col>
                <a-col 
                    :xxl="24" 
                    :lg="24" 
                    :md="24"
                    :xs="24">
                    <span class="text-description"> {{  dataDOM.description }} </span>
                </a-col>
            </a-row>
        </sdCards>
    </a-col>
  </a-row>
</template>
<script>

import { reactive } from 'vue';
//import { reactive, defineAsyncComponent, h } from 'vue';
//import { useRouter } from "vue-router";
//import { getEventsOnValidation } from '@/services/events.js'

//const DataTable = defineAsyncComponent(() => import('../../components/DataTable/DataTable.vue'));


const ValidationDescriptionComponent = {
  name: 'ValidationDescriptionComponent',
  components: {
  },
  props: {
    description: null,
    img: null,
  },
  async setup(props) {
    
    var dataDOM = reactive({
      showLoader: true,
      description: props.description,
      image: props.img,
      base_url: process.env.VUE_APP_BASE_URL
    });

    return {
      dataDOM
    };
  },
};

export default ValidationDescriptionComponent;
</script>
<style scoped lang="sass">
.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #9ED2EF
  border-radius: 7px
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
  cursor: pointer
.text-description
    color: #7B868C
.card
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
</style>
